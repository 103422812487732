@import '../Page.scss';

a {
	display: flex;
	justify-content: center;
	flex-direction: row;

	.button {
		margin-bottom: 1rem;
	}
}

.flex-spacer {
	flex-grow: 1;
}