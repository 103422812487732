$bg-color: white;
$color-selected: white;

.tab-bar {
	width: 100%;
	position: fixed;
	bottom: 0;

	.tab-bar-items-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background: $bg-color;
		padding: 0.4rem 0.5rem;
		border-radius: 1rem 1rem 0 0;
		box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.10);
	}

	.tab-item {
		margin: 0 0.5rem;
		padding: 0.4rem 0.5rem;
	}

	.tab-bar-spacer {
		width: 2px;
		height: 1.5rem;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.tab-item-selected .tab-item {
		background-color: #BEEBE9;
		border-radius: 0.5rem;

		.tab-item-label {
			color: $color-selected;
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
		}
	}
	
	.tab-item-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	
		.tab-item-label {
			font-size: 1.0rem;
			font-family: 'Biryani';
			font-weight: 600;
			text-transform: uppercase;
		}
	
		.tab-item-icon {
			width: 1.75rem;
			height: 1.75rem;
			opacity: 100%;
		}
	}
}