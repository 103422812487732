$color-red-1: #FFB6B9;
$color-purple-1: #d7bdff;
$color-purple-2: #bf94ff;
$color-purple-3: #a970ff;
$color-yellow-1: #F6EEC7;
$color-blue-1: #BEEBE9;

$body-bg: $color-yellow-1;
$strong-1-color: $color-blue-1;
$strong-2-color: $color-red-1;

$light-text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
$button-color: white;
$button-bg: $color-purple-1;

$box-shadow-light: 0 2px 10px rgba(0, 0, 0, 0.20);

h1, h2 {
	text-align: center;
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	margin-block-end: 0 !important;
}

h1 {
	font-size: 1.75rem;
	font-weight: 600;
}

h2 {
	font-size: 1.50rem;
	font-weight: 500;
}

p {
	margin-top: 0.40rem;
	padding: 0 1rem;
	text-align: center;
	font-size: 1rem;
}

.strong-1 {
	color: $strong-1-color;
	text-shadow: $light-text-shadow;
}

.strong-2 {
	color: $strong-2-color;
	text-shadow: $light-text-shadow;
}

.button {
	background-color: $button-bg;
	padding: 0.75rem;
	border-radius: 1rem;
	font-weight: 600;
	color: $button-color;
	text-shadow: $light-text-shadow;
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(246, 238, 199, 0.6);
}