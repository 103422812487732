@import '../Global.scss';

.page {
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-wrapper {
	margin-top: 2rem;
	width: 80%;
	height: 100%;
	background-color: white;
	box-shadow: $box-shadow-light;
	border-radius: 1rem;
}