@media (min-width: 768px)  {
	.page-wrapper, .footer, .tab-bar-items-wrapper {
		max-width: 65%;
	}

	.tab-bar {
		display: flex;
		justify-content: center;

		.tab-bar-items-wrapper {
			flex-grow: 1;
		}
	}
}