@import 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap';
@import '../Page.scss';

.services {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	NavLink {
		cursor: pointer;
	}
}