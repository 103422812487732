@import 'https://fonts.googleapis.com/css2?family=Quicksand&family=Biryani:wght@200;600;800&family=Pacifico&display=swap';
@import './Global.scss';
@import './App.mobile.scss';

body {
	margin: 0;
	padding: 0;
	font-family: 'Quicksand', cursive;
	background-color: $body-bg;
	color: #2e2a1b;
}

#root, .main-wrapper {
	min-height: 100vh;
	height: 100vh;
}

a:link, a:visited {
	color: inherit;
	text-decoration: none;
}

.main-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

	.main-outlet {
		flex-grow: 1;
	}
}

.header {
	display: flex;
	justify-content: center;
	padding: 0.80rem 0;
	background: #BEEBE9;

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 4rem;
			height: 4rem;
			border-radius: 2.5rem;
			border: 4px solid white;
			background-color: white;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
		}

		span {
			margin-left: 0.45rem;
			font-size: 2rem;
			color: white;
			text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.55);
			font-family: 'Pacifico';
		}
	}
}

.twitch-banner {
	background: #bf94ff;
	padding: 0.25rem;
	text-align: center;
	box-shadow: $box-shadow-light;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
	}

	figure {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		margin-right: 0.25rem;
		margin-top: 0.16rem;
	}
}

.footer-wrapper {
	display: flex;
	justify-content: center;

	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		background-color: $color-purple-2;
		padding: 0.5rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-size: 0.75rem;
		border-radius: 0.6rem;
		box-shadow: $box-shadow-light;

		a {
			font-weight: 600;
		}
	}
}

#bottom-spacer {
	width: 100vw;
	min-height: 82px;
}